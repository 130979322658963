import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { TourProvider } from "@reactour/tour";
import { injectIntl } from "react-intl";
import YumAlarmRoutes from "./YumAlarmRoutes";
import Footer from "./pages/fragments/Footer";
import Header from "./pages/fragments/Header";
import { TOUR_STEPS_CLASSES } from "./constants/Constants";
import { TourContext } from "./wrappers/tour/TourWrapper";
import { useContext } from "react";
import { UserContext } from "./wrappers/user/UserDataWrapper";
import Loading from "./components/loading/Loading";

function App({ intl }) {
  const { setShowingTour } = useContext(TourContext);
  const { user } = useContext(UserContext);

  let tourSteps = [
    {
      selector: TOUR_STEPS_CLASSES.STEP_1.selector,
      content: intl.formatMessage({ id: "tour.step1" }),
    },
    {
      selector: TOUR_STEPS_CLASSES.STEP_2.selector,
      content: intl.formatMessage({ id: "tour.step2" }),
    },
    {
      selector: TOUR_STEPS_CLASSES.STEP_3.selector,
      content: intl.formatMessage({ id: "tour.step3" }),
    },
    {
      selector: TOUR_STEPS_CLASSES.STEP_4.selector,
      content: intl.formatMessage({ id: "tour.step4" }),
    },
  ];

  return (
    <TourProvider
      steps={tourSteps}
      badgeContent={({ totalSteps, currentStep }) =>
        currentStep + 1 + "/" + totalSteps
      }
      styles={{
        popover: (base) => ({
          ...base,
          "--reactour-accent": "#4f46e5",
        }),
        maskArea: (base) => ({ ...base }),
        maskWrapper: (base) => ({ ...base }),
        badge: (base) => ({ ...base, right: "auto", left: "-0.8125em" }),
        controls: (base) => ({ ...base }),
        close: (base) => ({ ...base, left: "auto", right: 8, top: 8 }),
      }}
      onClickClose={({ setIsOpen }) => {
        setShowingTour(false);
        setIsOpen(false);
      }}
      onClickMask={({ setIsOpen }) => {
        setShowingTour(false);
        setIsOpen(false);
      }}
    >
      <Header />
      {!user && <Loading />}
      {user && (
        <main className="bg-white shadow flex-1">
          <YumAlarmRoutes />
          <ToastContainer />
        </main>
      )}
      <Footer />
    </TourProvider>
  );
}

export default injectIntl(App);
