export function validateMeal(meal, intl) {
  let isValid = true;
  let validationErrors = {};

  if (!meal) {
    isValid = false;
    validationErrors["name"] = intl.formatMessage({
      id: "meal.name.validation.empty",
    });
  }

  if (!meal.name || meal.name.length <= 0) {
    isValid = false;
    validationErrors["name"] = intl.formatMessage({
      id: "meal.name.validation.cannot.be.empty",
    });
  }

  if (!meal.category) {
    isValid = false;
    validationErrors["category"] = intl.formatMessage({
      id: "category.validation.empty",
    });
  } else if (!meal.category.id || meal.category.id.length <= 0) {
    isValid = false;
    validationErrors["category"] = intl.formatMessage({
      id: "category.validation.empty",
    });
  } else if (!meal.category.name || meal.category.name.length <= 0) {
    isValid = false;
    validationErrors["category"] = intl.formatMessage({
      id: "category.validation.empty",
    });
  }

  return { isValid: isValid, validationErrors: validationErrors };
}
