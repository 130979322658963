import {
  getCategory,
  getNotification,
  listNotifications,
} from "../graphql/queries";
import { toast } from "react-toastify";
import { executeGraphQL } from "./APIHelper";

export async function loadNotification(
  notificationId,
  setNotification,
  setLoading,
  showBoundary,
  intl
) {
  const apiData = await executeGraphQL(
    getNotification,
    {
      id: notificationId,
    },
    null,
    showBoundary
  );

  if (!apiData || !apiData.getNotification) {
    toast.error(
      intl.formatMessage({ id: "apihelper.loadNotification.error" }),
      { position: "bottom-right" }
    );
    return;
  }

  const categories = await getNotificationCategories(
    apiData.getNotification.categories,
    showBoundary,
    intl
  );

  setNotification({
    id: apiData.getNotification.id,
    name: apiData.getNotification.name,
    sendMonday: apiData.getNotification.sendMonday,
    sendTuesday: apiData.getNotification.sendTuesday,
    sendWednesday: apiData.getNotification.sendWednesday,
    sendThursday: apiData.getNotification.sendThursday,
    sendFriday: apiData.getNotification.sendFriday,
    sendSaturday: apiData.getNotification.sendSaturday,
    sendSunday: apiData.getNotification.sendSunday,
    paused: apiData.getNotification.paused,
    categories: [...categories],
  });
  setLoading(false);
}

async function getNotificationCategories(categories, showBoundary, intl) {
  const resolvedCategories = [];

  for (const category of categories) {
    const apiData = await executeGraphQL(
      getCategory,
      {
        id: category.categoryID,
      },
      null,
      showBoundary
    );

    if (apiData && apiData.getCategory) {
      const { id, name } = apiData.getCategory;

      resolvedCategories.push({
        category: { id: id, name: name },
        number: category.number,
      });
    } else {
      toast.error(intl.formatMessage({ id: "apihelper.loadCategory.error" }), {
        position: "bottom-right",
      });
    }
  }

  return resolvedCategories;
}

export async function getNotificationCount(showBoundary, intl) {
  let notifications = await fetchAllNotifications(showBoundary, intl);
  return notifications.length;
}

async function fetchAllNotifications(showBoundary, intl) {
  let apiData = await executeGraphQL(
    listNotifications,
    null,
    null,
    showBoundary
  );

  if (apiData && apiData.listNotifications) {
    let notifications = apiData.listNotifications.items;

    while (apiData.listNotifications.nextToken != null) {
      apiData = await executeGraphQL(
        listNotifications,
        {
          nextToken: apiData.listNotifications.nextToken,
        },
        null,
        showBoundary
      );

      if (apiData && apiData.listNotifications) {
        notifications.push(...apiData.listNotifications.items);
      } else {
        toast.error(
          intl.formatMessage({ id: "apihelper.loadNotifications.error" }),
          {
            position: "bottom-right",
          }
        );
        return [];
      }
    }

    return notifications;
  } else {
    toast.error(
      intl.formatMessage({ id: "apihelper.loadNotifications.error" }),
      {
        position: "bottom-right",
      }
    );
    return [];
  }
}

export async function getNotificationsUsingCategory(
  categoryId,
  showBoundary,
  intl
) {
  let notifications = await fetchAllNotifications(showBoundary, intl);

  let results = [];

  for (let notification of notifications) {
    for (let notificationCategory of notification.categories) {
      if (categoryId === notificationCategory.categoryID) {
        results.push(notification);
        break;
      }
    }
  }

  return results;
}

export function getInitialNotification() {
  return {
    id: "",
    name: "",
    categories: [
      {
        category: {
          id: "",
          name: "",
        },
        number: 1,
      },
    ],
    sendMonday: false,
    sendTuesday: false,
    sendWednesday: false,
    sendThursday: false,
    sendFriday: false,
    sendSaturday: false,
    sendSunday: false,
    paused: false,
  };
}
