import { Link } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useState, useEffect } from "react";
import Loading from "../../components/loading/Loading";
import Table from "./Table";

function EmptyList({ title, description, newText }) {
  return (
    <div className="text-center p-y-8">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-900">{title}</h3>
      <p className="mt-1 text-sm text-gray-500">{description}</p>
      <div className="mt-6">
        <Link to={"add"}>
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            {newText}
          </button>
        </Link>
      </div>
    </div>
  );
}

export default function List({
  title,
  removeFunction,
  emptyListTitle,
  emptyListDescription,
  emptyListNewText,
  fetchItemsGraphQLQuery,
}) {
  const [items, setItems] = useState([]);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState();
  const [previousTokens, setPreviousTokens] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadItems = async () => {
    setLoading(true);
    const { items, token } = await fetchItemsGraphQLQuery(nextToken);

    setItems(items);
    setNextNextToken(token);
    setLoading(false);
  };

  useEffect(() => {
    loadItems();

    return () => {};
  }, []);

  useEffect(() => {
    loadItems();

    return () => {};
  }, [nextToken]);

  const next = async () => {
    setPreviousTokens((prev) => [...prev, nextToken]);
    setNextToken(nextNextToken);
    setNextNextToken(null);
  };

  const prev = async () => {
    setNextToken(previousTokens.pop());
    setPreviousTokens([...previousTokens]);
    setNextNextToken(null);
  };

  const hanldeRemove = async (id) => {
    setLoading(true);
    await removeFunction(id);
    await loadItems();
    setLoading(false);
  };

  let finalPrev = undefined;
  if (previousTokens.length > 0) {
    finalPrev = prev;
  }
  let finalNext = next;
  if (nextNextToken === null) {
    finalNext = undefined;
  }

  if (loading) {
    return <Loading />;
  } else if (!items || items.length === 0) {
    return (
      <EmptyList
        title={emptyListTitle}
        description={emptyListDescription}
        newText={emptyListNewText}
      />
    );
  } else {
    return (
      <Table
        title={title}
        items={items}
        removeFunction={hanldeRemove}
        prev={finalPrev}
        next={finalNext}
      />
    );
  }
}
