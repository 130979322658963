import { useState, useEffect, createContext } from "react";
import { LOCAL_STORAGE } from "../../constants/Constants";

export const TourContext = createContext();

function TourWrapper({ children }) {
  const [showTourModal, setShowTourModal] = useState(false);
  const [showingTour, setShowingTour] = useState(false);

  useEffect(() => {
    let disableTour = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE.DISABLE_TOUR_MODAL)
    );

    if (!disableTour) {
      setShowTourModal(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE.DISABLE_TOUR_MODAL,
      JSON.stringify(!showTourModal)
    );
  }, [showTourModal]);

  return (
    <TourContext.Provider
      value={{ showTourModal, setShowTourModal, showingTour, setShowingTour }}
    >
      {children}
    </TourContext.Provider>
  );
}

export default TourWrapper;
