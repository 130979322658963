import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../wrappers/user/UserDataWrapper";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";
import FormTitle from "../../components/form-title/FormTitle";
import TextInput from "../../components/input/text/TextInput";

function ViewSettingsPage() {
  const { user } = useContext(UserContext);

  return (
    <HalfWidthPageWrapper>
      <form>
        <div className="space-y-10 sm:space-y-12">
          <FormTitle titleKey="settings" />

          <div className="mt-10 space-y-8 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:pb-0">
            <TextInput
              labelTextKey="E-mail"
              id="e-mail"
              value={user?.email}
              disabled={true}
            />

            <TextInput
              labelTextKey="password"
              name="password"
              id="password"
              value="**********"
              disabled={true}
            />
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <Link to="/settings/edit/email">
            <button
              type="button"
              className="rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <FormattedMessage id="update.e-mail" />
            </button>
          </Link>

          <Link to="/settings/edit/password">
            <button
              type="button"
              className="rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <FormattedMessage id="update.password" />
            </button>
          </Link>
        </div>
      </form>
    </HalfWidthPageWrapper>
  );
}

export default ViewSettingsPage;
