import { injectIntl, FormattedMessage } from "react-intl";
import { useContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import Loading from "../../components/loading/Loading";
import {
  getUserCategoriesLimit,
  getUserMealsLimit,
  getUserNotificationsLimit,
} from "../../helpers/SubscriptionHelper";
import { getMealCount } from "../../helpers/MealCommon";
import { getCategoryCount } from "../../helpers/CategoryCommon";
import { getNotificationCount } from "../../helpers/NotificationCommon";
import { UserContext } from "../../wrappers/user/UserDataWrapper";
import PageTitle from "../../components/page-title/PageTitle";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";

const usageParts = [
  { nameMessageKey: "Categories", statePropertyKey: "categories" },
  { nameMessageKey: "Meals", statePropertyKey: "meals" },
  { nameMessageKey: "Notifications", statePropertyKey: "notifications" },
];

function UsagePage({ intl }) {
  const [usage, setUsage] = useState({
    meals: { count: 0, limit: 0 },
    categories: { count: 0, limit: 0 },
    notifications: { count: 0, limit: 0 },
  });
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext);
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const getUsageData = async () => {
      if (!user) {
        return;
      }

      const mealLimit = getUserMealsLimit(user);
      const categoryLimit = getUserCategoriesLimit(user);
      const notificationLimit = getUserNotificationsLimit(user);
      const mealCount = await getMealCount(showBoundary, intl);
      const categoryCount = await getCategoryCount(showBoundary, intl);
      const notificationCount = await getNotificationCount(showBoundary, intl);

      setUsage({
        meals: { count: mealCount, limit: mealLimit },
        categories: { count: categoryCount, limit: categoryLimit },
        notifications: { count: notificationCount, limit: notificationLimit },
      });
      setLoading(false);
    };

    getUsageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function getLimitString(limit) {
    if (limit === Infinity) {
      return "∞";
    } else {
      return limit;
    }
  }

  function getBarColor(percentageUsed) {
    if (percentageUsed < 60) {
      return "bg-green-500";
    }
    if (percentageUsed < 80) {
      return "bg-yellow-500";
    }
    return "bg-red-500";
  }

  const renderStats = () => {
    return (
      <>
        <PageTitle titleKey="usage" />

        <div className="w-full flex flex-col lg:flex-row">
          {usageParts.map((usagePart) => {
            const count = usage[usagePart.statePropertyKey].count;
            const limit = usage[usagePart.statePropertyKey].limit;
            const percentageUsed = (count / limit) * 100;

            return (
              <div className="w-full flex flex-col items-center px-4 py-5 lg:mx-3 lg:w-1/3 lg:rounded-xl lg:shadow-lg lg:border-4 lg:border-solid lg:border-gray-300">
                <div className="text-2xl text-center font-medium py-2">
                  <FormattedMessage id={usagePart.nameMessageKey} />
                </div>
                <div className="h-2 w-full bg-neutral-600 lg:h-6 lg:text-center">
                  <div
                    className={"h-2 lg:h-6 " + getBarColor(percentageUsed)}
                    style={{
                      width: `${percentageUsed}%`,
                    }}
                  ></div>
                </div>
                <div className="text-center mt-1 text-2xl font-semibold tracking-tight">
                  {count} / {getLimitString(limit)}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <HalfWidthPageWrapper>
      {loading && <Loading />}
      {!loading && renderStats()}
    </HalfWidthPageWrapper>
  );
}

export default injectIntl(UsagePage);
