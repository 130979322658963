import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import CategoryAutocomplete from "../../components/autocomplete/CategoryAutocomplete";
import { injectIntl, FormattedMessage } from "react-intl";
import SelectInput from "../../components/input/select/SelectInput";
import { validateNotification } from "../../validation/NotificationValidation";
import { updateNotification } from "../../graphql/mutations";
import { executeGraphQL } from "../../helpers/APIHelper";
import { useErrorBoundary } from "react-error-boundary";
import { toast } from "react-toastify";
import Loading from "../../components/loading/Loading";
import { loadAllCategories } from "../../helpers/CategoryCommon";
import {
  getInitialNotification,
  loadNotification,
} from "../../helpers/NotificationCommon";
import TextInput from "../../components/input/text/TextInput";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";
import FormTitle from "../../components/form-title/FormTitle";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";

function EditNotificationPage({ intl }) {
  const { notificationId } = useParams();
  const [notification, setNotification] = useState(getInitialNotification());
  const [categories, setCategories] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    loadNotification(
      notificationId,
      setNotification,
      setLoading,
      showBoundary,
      intl
    );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationId]);

  useEffect(() => {
    loadAllCategories(setCategories, showBoundary, intl);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveClick = async () => {
    const validationResult = validateNotification(notification, intl);
    setValidationErrors(validationResult.validationErrors);

    if (!validationResult.isValid) {
      return;
    }

    const apiData = await executeGraphQL(
      updateNotification,
      {
        input: {
          id: notification.id,
          name: notification.name,
          sendMonday: notification.sendMonday,
          sendTuesday: notification.sendTuesday,
          sendWednesday: notification.sendWednesday,
          sendThursday: notification.sendThursday,
          sendFriday: notification.sendFriday,
          sendSaturday: notification.sendSaturday,
          sendSunday: notification.sendSunday,
          paused: notification.paused,
          categories: notification.categories.map((category) => {
            return {
              number: category.number,
              categoryID: category.category.id,
            };
          }),
        },
      },
      intl.formatMessage({ id: "apihelper.updateNotification.success" }),
      showBoundary
    );

    if (apiData && apiData.updateNotification) {
      setNotification({ ...notification, id: apiData.updateNotification.id });
      navigate("/notifications/view/" + apiData.updateNotification.id);
    } else {
      toast.error(
        intl.formatMessage({ id: "apihelper.updateNotification.error" }),
        { position: "bottom-right" }
      );
    }
  };

  function renderCategoryInputs() {
    return notification.categories.map((categoryInput, index) => {
      return (
        <fieldset
          key={`category-${index}-number`}
          className="py-6 border-y border-gray-300 sm:py-0 sm:border-y-0"
        >
          <CategoryAutocomplete
            categories={categories}
            onCategorySelected={(selectedCategory) => {
              notification.categories[index].category = selectedCategory;
              setNotification({ ...notification });
            }}
            selectedCategory={categoryInput.category}
            validationError={validationErrors[`category-${index}-name`]}
          />
          <TextInput
            labelTextKey="category.number.meals"
            id={`category-${index}-meals`}
            value={categoryInput.number}
            handleOnChange={(event) => {
              categoryInput.number = event.target.value;
              setNotification({ ...notification });
            }}
            validationError={validationErrors[`category-${index}-number`]}
          />
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:pb-6">
            <div
              className="mt-2 sm:col-start-3 sm:mt-0 cursor-pointer text-red-500 w-full flex items-center justify-center text-center"
              onClick={() => {
                notification.categories.splice(index, 1);
                setNotification({
                  ...notification,
                  categories: [...notification.categories],
                });
              }}
            >
              <TrashIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
              <FormattedMessage id="category.remove" />
            </div>
          </div>
        </fieldset>
      );
    });
  }

  const renderForm = () => {
    return (
      <form>
        <div className="space-y-10 sm:space-y-12">
          <FormTitle titleKey="notification.edit" />

          <div className="mt-10 space-y-8 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:pb-0">
            <TextInput
              labelTextKey="notification.name"
              id="notification-name"
              validationError={validationErrors["name"]}
              value={notification.name}
              handleOnChange={(event) => {
                setValidationErrors({ ...validationErrors, name: null });
                setNotification({
                  ...notification,
                  name: event.target.value,
                });
              }}
            />

            <fieldset>
              <legend className="sr-only">Send notification on days</legend>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6">
                <div
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  aria-hidden="true"
                >
                  <FormattedMessage id="notification.send.on" />
                </div>
                <div className="mt-4 sm:col-span-2 sm:mt-0">
                  <div className="max-w-lg space-y-6">
                    <SelectInput
                      id="sendMonday"
                      value={notification.sendMonday}
                      setValue={(event) => {
                        setNotification({
                          ...notification,
                          sendMonday: event.target.checked,
                        });
                      }}
                    />
                    <SelectInput
                      id="sendTuesday"
                      value={notification.sendTuesday}
                      setValue={(event) => {
                        setNotification({
                          ...notification,
                          sendTuesday: event.target.checked,
                        });
                      }}
                    />
                    <SelectInput
                      id="sendWednesday"
                      value={notification.sendWednesday}
                      setValue={(event) => {
                        setNotification({
                          ...notification,
                          sendWednesday: event.target.checked,
                        });
                      }}
                    />
                    <SelectInput
                      id="sendThursday"
                      value={notification.sendThursday}
                      setValue={(event) => {
                        setNotification({
                          ...notification,
                          sendThursday: event.target.checked,
                        });
                      }}
                    />
                    <SelectInput
                      id="sendFriday"
                      value={notification.sendFriday}
                      setValue={(event) => {
                        setNotification({
                          ...notification,
                          sendFriday: event.target.checked,
                        });
                      }}
                    />
                    <SelectInput
                      id="sendSaturday"
                      value={notification.sendSaturday}
                      setValue={(event) => {
                        setNotification({
                          ...notification,
                          sendSaturday: event.target.checked,
                        });
                      }}
                    />
                    <SelectInput
                      id="sendSunday"
                      value={notification.sendSunday}
                      setValue={(event) => {
                        setNotification({
                          ...notification,
                          sendSunday: event.target.checked,
                        });
                      }}
                    />
                    {validationErrors["sendOn"] && (
                      <span className="relative flex gap-x-3 text-sm text-red-600">
                        {validationErrors["sendOn"]}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </fieldset>
            {renderCategoryInputs()}
            <div className="flex items-center justify-center sm:grid sm:grid-cols-3 sm:items-start sm:py-6">
              {validationErrors["categories"] && (
                <span className="mb-2 sm:col-span-1 sm:col-start-2 text-sm text-red-600">
                  {validationErrors["categories"]}
                </span>
              )}
              <button
                className="mt-2 sm:col-start-2 sm:self-center sm:justify-self-center sm:mt-0 rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center justify-center"
                onClick={(e) => {
                  e.preventDefault();
                  notification.categories.push({
                    category: { id: "", name: "" },
                    number: 1,
                  });
                  setNotification({
                    ...notification,
                    categories: [...notification.categories],
                  });
                }}
              >
                <PlusIcon className="h-8 w-8 text-white" aria-hidden="true" />
                <FormattedMessage id="category.add" />
              </button>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <Link
            to="/notifications"
            className="text-sm font-semibold leading-6 text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <FormattedMessage id="Cancel" />
          </Link>

          <button
            type="button"
            className="rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSaveClick}
          >
            <FormattedMessage id="Save" />
          </button>
        </div>
      </form>
    );
  };

  return (
    <HalfWidthPageWrapper>
      {loading && <Loading />}
      {!loading && renderForm()}
    </HalfWidthPageWrapper>
  );
}

export default injectIntl(EditNotificationPage);
