import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import backgroundImage from "../../assets/images/background-call-to-action.jpg";

function CallToAction() {
  return (
    <section
      id="get-started-today"
      className="relative overflow-hidden bg-blue-600 py-32"
    >
      <img
        className="absolute left-1/2 top-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
        src={backgroundImage}
        alt=""
        width={2347}
        height={1244}
        unoptimized="true"
      />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            <FormattedMessage id="cta.title" />
          </h2>
          <p className="mt-4 text-lg tracking-tight text-white">
            <FormattedMessage id="cta.description" />
          </p>
          <div className="mt-10 flex justify-center gap-x-6">
            <Link to={"/subscribe"}>
              <button
                type="button"
                className="block rounded-md bg-white py-2 px-3 text-center text-sm font-semibold text-slate-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <FormattedMessage id="cta.cta" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CallToAction;
