import { useState, useEffect, createContext } from "react";
import { fetchUserAttributes } from "aws-amplify/auth";
import { useAuthenticator } from "@aws-amplify/ui-react";

export const UserContext = createContext();

function UserDataWrapper({ children }) {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  const loadAttributes = async () => {
    if (authStatus === "authenticated") {
      try {
        const response = await fetchUserAttributes();
        setUser({ ...response });
        setIsLoggedIn(true);
      } catch (error) {
        setUser({});
        setIsLoggedIn(false);
      }
    } else {
      setUser({});
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    loadAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus]);

  async function refreshUser() {
    loadAttributes();
  }

  function signOutUser() {
    setUser({});
    setIsLoggedIn(false);
  }

  return (
    <UserContext.Provider
      value={{ isLoggedIn, user, refreshUser, signOutUser }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserDataWrapper;
