import { getMealCount } from "./MealCommon";
import { getNotificationCount } from "./NotificationCommon";
import {
  getUserCategoriesLimit,
  getUserMealsLimit,
  getUserNotificationsLimit,
} from "./SubscriptionHelper";
import { getCategoryCount } from "./CategoryCommon";

export async function canAddCategories(userAttributes, showBoundary, intl) {
  let categoryLimit = getUserCategoriesLimit(userAttributes);

  if (categoryLimit === Infinity) {
    return true;
  }

  let categoryCount = await getCategoryCount(showBoundary, intl);

  return categoryCount < categoryLimit;
}

export async function canAddMeals(userAttributes, showBoundary, intl) {
  let mealLimit = getUserMealsLimit(userAttributes);

  if (mealLimit === Infinity) {
    return true;
  }

  let mealCount = await getMealCount(showBoundary, intl);

  return mealCount < mealLimit;
}

export async function canAddNotifications(userAttributes, showBoundary, intl) {
  let notificationLimit = getUserNotificationsLimit(userAttributes);
  let notificationCount = await getNotificationCount(showBoundary, intl);

  return notificationCount < notificationLimit;
}
