import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { updateCategory } from "../../graphql/mutations";
import { executeGraphQL } from "../../helpers/APIHelper";
import { Link } from "react-router-dom";
import { validateCategory } from "../../validation/CategoryValidation";
import { useErrorBoundary } from "react-error-boundary";
import { toast } from "react-toastify";
import Loading from "../../components/loading/Loading";
import { getInitialCategory, loadCategory } from "../../helpers/CategoryCommon";
import TextInput from "../../components/input/text/TextInput";
import TextAreaInput from "../../components/input/text/TextAreaInput";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";
import FormTitle from "../../components/form-title/FormTitle";

function EditCategoryPage({ intl }) {
  const { categoryId } = useParams();
  const [category, setCategory] = useState(getInitialCategory());
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    loadCategory(categoryId, setCategory, setLoading, showBoundary, intl);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  const handleSaveClick = async () => {
    const validationResult = validateCategory(category, intl);
    setValidationErrors(validationResult.validationErrors);

    if (!validationResult.isValid) {
      return;
    }

    const apiData = await executeGraphQL(
      updateCategory,
      {
        input: {
          id: category.id,
          name: category.name,
          description: category.description,
        },
      },
      intl.formatMessage({ id: "apihelper.updateCategory.success" }),
      showBoundary
    );

    if (apiData && apiData.updateCategory) {
      setCategory({ ...category, id: apiData.updateCategory.id });
      navigate("/categories/view/" + apiData.updateCategory.id);
    } else {
      toast.error(
        intl.formatMessage({ id: "apihelper.updateCategory.error" }),
        {
          position: "bottom-right",
        }
      );
    }
  };

  const renderForm = () => {
    return (
      <form>
        <div className="space-y-10 sm:space-y-12">
          <FormTitle titleKey="category.edit" />

          <div className="mt-10 space-y-8 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:pb-0">
            <TextInput
              labelTextKey="category.name"
              id="category-name"
              validationError={validationErrors["name"]}
              value={category.name}
              handleOnChange={(event) => {
                setValidationErrors({ ...validationErrors, name: null });
                setCategory({
                  ...category,
                  name: event.target.value,
                });
              }}
            />
            <TextAreaInput
              labelTextKey="Description"
              id="category-description"
              value={category.description}
              handleOnChange={(event) =>
                setCategory({
                  ...category,
                  description: event.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <Link
            to="/categories"
            className="text-sm font-semibold leading-6 text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <FormattedMessage id="Cancel" />
          </Link>

          <button
            type="button"
            className="rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSaveClick}
          >
            <FormattedMessage id="Save" />
          </button>
        </div>
      </form>
    );
  };

  return (
    <HalfWidthPageWrapper>
      {loading && <Loading />}
      {!loading && renderForm()}
    </HalfWidthPageWrapper>
  );
}

export default injectIntl(EditCategoryPage);
