import "react-toastify/dist/ReactToastify.css";
import { Fragment, useContext } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { FormattedMessage } from "react-intl";
import logo from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { TOUR_STEPS_CLASSES } from "../../constants/Constants";
import { TourContext } from "../../wrappers/tour/TourWrapper";
import { UserContext } from "../../wrappers/user/UserDataWrapper";
import { joinClasses } from "../../helpers/Utils";

const navigation = [
  {
    messageKey: "navigation.menu.random.meal",
    linkTo: "/generate",
    tourSelectorClasses: TOUR_STEPS_CLASSES.STEP_4.className,
  },
  {
    messageKey: "Categories",
    linkTo: "/categories",
    tourSelectorClasses: TOUR_STEPS_CLASSES.STEP_1.className,
  },
  {
    messageKey: "Meals",
    linkTo: "/meals",
    tourSelectorClasses: TOUR_STEPS_CLASSES.STEP_2.className,
  },
  {
    messageKey: "Notifications",
    linkTo: "/notifications",
    tourSelectorClasses: TOUR_STEPS_CLASSES.STEP_3.className,
  },
];

const anonymousNavigation = [
  {
    messageKey: "navigation.menu.login",
    linkTo: "/login",
  },
  {
    messageKey: "Subscribe",
    linkTo: "/subscribe",
  },
];

const userNavigation = [
  { messageKey: "settings", linkTo: "/settings" },
  {
    messageKey: "subscription.manage",
    linkTo: process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK,
  },
  { messageKey: "usage", linkTo: "/usage" },
  { messageKey: "referrals", linkTo: "/referrals" },
];

function isMobile() {
  return window.innerWidth <= 768;
}

function Header() {
  const { showingTour } = useContext(TourContext);
  const { isLoggedIn } = useContext(UserContext);

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Link to="/">
                    <img className="h-12" src={logo} alt="YumAlarm" />
                  </Link>
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {isLoggedIn ? <LoggedInNavigation /> : <GuestNavigation />}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  {isLoggedIn && <ProfileDropdown />}
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  {open || showingTour ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden" static={showingTour}>
            <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
              {isLoggedIn ? (
                <MobileLoggedInNavigation />
              ) : (
                <MobileGuestNavigation />
              )}
            </div>
            {isLoggedIn && <MobileProfileDropdown />}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

function ProfileDropdown() {
  const { user, signOutUser } = useContext(UserContext);
  const { signOut } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  const location = useLocation();

  const logOut = () => {
    signOut();
    signOutUser();
    navigate("/login");
  };

  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
          <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
            <span className="text-sm font-medium leading-none text-white">
              {user?.email?.charAt(0).toUpperCase()}
            </span>
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {userNavigation.map((item, idx) => (
            <Menu.Item key={"user-navigation-" + idx}>
              <Link
                to={item.linkTo}
                className={joinClasses(
                  item.linkTo === location.pathname ? "bg-gray-100" : "",
                  "block px-4 py-2 text-sm text-gray-700"
                )}
              >
                <FormattedMessage id={item.messageKey} />
              </Link>
            </Menu.Item>
          ))}
          <Menu.Item>
            <button
              className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
              onClick={logOut}
            >
              <FormattedMessage id="sign.out" />
            </button>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

function MobileProfileDropdown() {
  const { user, signOutUser } = useContext(UserContext);
  const { signOut } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  const location = useLocation();

  const logOut = () => {
    signOut();
    signOutUser();
    navigate("/login");
  };

  return (
    <div className="border-t border-gray-700 pt-4 pb-3">
      <div className="flex items-center px-5">
        <div className="flex-shrink-0">
          <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500">
            <span className="font-medium leading-none text-white">
              {user?.email?.charAt(0).toUpperCase()}
            </span>
          </span>
        </div>
        <div className="ml-3">
          <div className="text-sm font-medium leading-none text-gray-400">
            {user?.email}
          </div>
        </div>
      </div>
      <div className="mt-3 space-y-1 px-2">
        {userNavigation.map((item, idx) => (
          <Disclosure.Button
            key={"user-navigation-" + idx}
            as="a"
            href={item.linkTo}
            className={joinClasses(
              item.linkTo === location.pathname ? "bg-gray-900 " : "",
              "block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
            )}
          >
            <FormattedMessage id={item.messageKey} />
          </Disclosure.Button>
        ))}
        <Disclosure.Button
          as="a"
          onClick={logOut}
          className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white cursor-pointer"
        >
          <FormattedMessage id="sign.out" />
        </Disclosure.Button>
      </div>
    </div>
  );
}

function LoggedInNavigation() {
  const location = useLocation();

  return navigation.map((item, idx) => (
    <Link
      key={"navigation-" + idx}
      to={item.linkTo}
      className={joinClasses(
        item.linkTo === location.pathname
          ? "bg-gray-900 text-white"
          : "text-gray-300 hover:bg-gray-700 hover:text-white",
        "rounded-md px-3 py-2 text-sm font-medium",
        !isMobile() ? item.tourSelectorClasses : ""
      )}
      aria-current={item.linkTo === location.pathname ? "page" : undefined}
    >
      <FormattedMessage id={item.messageKey} />
    </Link>
  ));
}

function GuestNavigation() {
  const location = useLocation();

  return anonymousNavigation.map((item, idx) => (
    <Link
      key={"anonymous-navigation-" + idx}
      to={item.linkTo}
      className={joinClasses(
        item.linkTo === location.pathname
          ? "bg-gray-900 text-white"
          : "text-gray-300 hover:bg-gray-700 hover:text-white",
        "rounded-md px-3 py-2 text-sm font-medium"
      )}
      aria-current={item.linkTo === location.pathname ? "page" : undefined}
    >
      <FormattedMessage id={item.messageKey} />
    </Link>
  ));
}

function MobileLoggedInNavigation() {
  const location = useLocation();

  return navigation.map((item, idx) => (
    <Disclosure.Button
      key={"mobile-navigation-" + idx}
      as="a"
      href={item.linkTo}
      className={joinClasses(
        item.linkTo === location.pathname
          ? "bg-gray-900 text-white"
          : "text-gray-300 hover:bg-gray-700 hover:text-white",
        "block rounded-md px-3 py-2 text-base font-medium",
        isMobile() ? item.tourSelectorClasses : ""
      )}
      aria-current={item.linkTo === location.pathname ? "page" : undefined}
    >
      <FormattedMessage id={item.messageKey} />
    </Disclosure.Button>
  ));
}

function MobileGuestNavigation() {
  const location = useLocation();

  return anonymousNavigation.map((item, idx) => (
    <Disclosure.Button
      key={"mobile-anonymous-navigation-" + idx}
      as="a"
      href={item.linkTo}
      className={joinClasses(
        item.linkTo === location.pathname
          ? "bg-gray-900 text-white"
          : "text-gray-300 hover:bg-gray-700 hover:text-white",
        "block rounded-md px-3 py-2 text-base font-medium"
      )}
      aria-current={item.linkTo === location.pathname ? "page" : undefined}
    >
      <FormattedMessage id={item.messageKey} />
    </Disclosure.Button>
  ));
}

export default Header;
