import { useLocation, Navigate } from "react-router-dom";
import { useContext } from "react";
import { isUserSubscribed } from "../../helpers/SubscriptionHelper";
import { UserContext } from "../user/UserDataWrapper";

function RequireAuthWrapper({ children }) {
  const location = useLocation();
  const { user, isLoggedIn } = useContext(UserContext);

  if (user === null) {
    return;
  }

  if (isLoggedIn) {
    if (isUserSubscribed(user)) {
      return children;
    } else {
      return <Navigate to="/subscribe" state={{ from: location }} replace />;
    }
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
}

export default RequireAuthWrapper;
