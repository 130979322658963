import { useState, useEffect, useContext } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { createCategory as createCategoryMutation } from "../../graphql/mutations";
import { executeGraphQL } from "../../helpers/APIHelper";
import { validateCategory } from "../../validation/CategoryValidation";
import { useErrorBoundary } from "react-error-boundary";
import { toast } from "react-toastify";
import { getInitialCategory } from "../../helpers/CategoryCommon";
import TextInput from "../../components/input/text/TextInput";
import { canAddCategories } from "../../helpers/PlanLimitHelper";
import LimitReached from "../../components/limitReached/LimitReached";
import TextAreaInput from "../../components/input/text/TextAreaInput";
import { UserContext } from "../../wrappers/user/UserDataWrapper";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";
import FormTitle from "../../components/form-title/FormTitle";

function AddCategoryPage({ intl }) {
  const [category, setCategory] = useState(getInitialCategory());
  const [validationErrors, setValidationErrors] = useState({});
  const [limitReached, setLimitReached] = useState(false);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const initalLoad = async () => {
      let limitNotReached = await canAddCategories(user, showBoundary, intl);
      setLimitReached(!limitNotReached);
    };

    initalLoad();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveClick = async () => {
    const validationResult = validateCategory(category, intl);

    if (!validationResult.isValid) {
      setValidationErrors(validationResult.validationErrors);
      return;
    }

    const apiData = await executeGraphQL(
      createCategoryMutation,
      {
        input: {
          name: category.name,
          description: category.description,
        },
      },
      intl.formatMessage({ id: "apihelper.createCategory.success" }),
      showBoundary
    );

    if (apiData && apiData.createCategory) {
      setCategory({ ...category, id: apiData.createCategory.id });
      navigate("/categories/view/" + apiData.createCategory.id, {
        state: {
          comingFromAddPage: true,
        },
      });
    } else {
      toast.error(
        intl.formatMessage({ id: "apihelper.createCategory.error" }),
        { position: "bottom-right" }
      );
    }
  };

  if (limitReached) {
    return (
      <HalfWidthPageWrapper>
        <LimitReached
          infoMessageKey="plan.limit.reached.categories.info"
          linkMessageKey="plan.limit.reached.link"
        />
      </HalfWidthPageWrapper>
    );
  } else {
    return (
      <HalfWidthPageWrapper>
        <form>
          <div className="space-y-10 sm:space-y-12">
            <FormTitle titleKey="category.add.new" />

            <div className="mt-10 space-y-8 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:pb-0">
              <TextInput
                labelTextKey="category.name"
                id="category-name"
                validationError={validationErrors["name"]}
                value={category.name}
                handleOnChange={(event) => {
                  setValidationErrors({});
                  setCategory({
                    ...category,
                    name: event.target.value,
                  });
                }}
              />
              <TextAreaInput
                labelTextKey="Description"
                id="category-description"
                value={category.description}
                handleOnChange={(event) =>
                  setCategory({
                    ...category,
                    description: event.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <Link
              to="/categories"
              className="text-sm font-semibold leading-6 text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <FormattedMessage id="Cancel" />
            </Link>

            <button
              type="button"
              className="rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleSaveClick}
            >
              <FormattedMessage id="Save" />
            </button>
          </div>
        </form>
      </HalfWidthPageWrapper>
    );
  }
}

export default injectIntl(AddCategoryPage);
