import { FormattedMessage } from "react-intl";

export default function FormTitle({ titleKey, descriptionKey }) {
  return (
    <>
      <h2 className="text-xl font-semibold leading-7 text-gray-900">
        <FormattedMessage id={titleKey} />
      </h2>
      {descriptionKey && (
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
          <FormattedMessage id={descriptionKey} />
        </p>
      )}
    </>
  );
}
