/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createCategory = /* GraphQL */ `mutation CreateCategory($input: CategoryCreateInput!) {
  createCategory(input: $input) {
    id
    createdAt
    updatedAt
    name
    description
    meals {
      id
      createdAt
      updatedAt
      categoryID
      category {
        id
        createdAt
        updatedAt
        name
        description
        meals {
          id
          createdAt
          updatedAt
          categoryID
          category {
            id
            createdAt
            updatedAt
            name
            description
            owner
            __typename
          }
          name
          description
          owner
          __typename
        }
        owner
        __typename
      }
      name
      description
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCategoryMutationVariables,
  APITypes.CreateCategoryMutation
>;
export const createMeal = /* GraphQL */ `mutation CreateMeal($input: MealCreateInput!) {
  createMeal(input: $input) {
    id
    createdAt
    updatedAt
    categoryID
    category {
      id
      createdAt
      updatedAt
      name
      description
      meals {
        id
        createdAt
        updatedAt
        categoryID
        category {
          id
          createdAt
          updatedAt
          name
          description
          meals {
            id
            createdAt
            updatedAt
            categoryID
            name
            description
            owner
            __typename
          }
          owner
          __typename
        }
        name
        description
        owner
        __typename
      }
      owner
      __typename
    }
    name
    description
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMealMutationVariables,
  APITypes.CreateMealMutation
>;
export const createNotification = /* GraphQL */ `mutation CreateNotification($input: NotificationCreateInput!) {
  createNotification(input: $input) {
    id
    createdAt
    updatedAt
    name
    sendMonday
    sendTuesday
    sendWednesday
    sendThursday
    sendFriday
    sendSaturday
    sendSunday
    categories {
      categoryID
      category {
        id
        createdAt
        updatedAt
        name
        description
        meals {
          id
          createdAt
          updatedAt
          categoryID
          category {
            id
            createdAt
            updatedAt
            name
            description
            owner
            __typename
          }
          name
          description
          owner
          __typename
        }
        owner
        __typename
      }
      number
      __typename
    }
    paused
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationMutationVariables,
  APITypes.CreateNotificationMutation
>;
export const updateCategory = /* GraphQL */ `mutation UpdateCategory($input: CategoryUpdateInput!) {
  updateCategory(input: $input) {
    id
    createdAt
    updatedAt
    name
    description
    meals {
      id
      createdAt
      updatedAt
      categoryID
      category {
        id
        createdAt
        updatedAt
        name
        description
        meals {
          id
          createdAt
          updatedAt
          categoryID
          category {
            id
            createdAt
            updatedAt
            name
            description
            owner
            __typename
          }
          name
          description
          owner
          __typename
        }
        owner
        __typename
      }
      name
      description
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCategoryMutationVariables,
  APITypes.UpdateCategoryMutation
>;
export const updateMeal = /* GraphQL */ `mutation UpdateMeal($input: MealUpdateInput!) {
  updateMeal(input: $input) {
    id
    createdAt
    updatedAt
    categoryID
    category {
      id
      createdAt
      updatedAt
      name
      description
      meals {
        id
        createdAt
        updatedAt
        categoryID
        category {
          id
          createdAt
          updatedAt
          name
          description
          meals {
            id
            createdAt
            updatedAt
            categoryID
            name
            description
            owner
            __typename
          }
          owner
          __typename
        }
        name
        description
        owner
        __typename
      }
      owner
      __typename
    }
    name
    description
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMealMutationVariables,
  APITypes.UpdateMealMutation
>;
export const updateNotification = /* GraphQL */ `mutation UpdateNotification($input: NotificationUpdateInput!) {
  updateNotification(input: $input) {
    id
    createdAt
    updatedAt
    name
    sendMonday
    sendTuesday
    sendWednesday
    sendThursday
    sendFriday
    sendSaturday
    sendSunday
    categories {
      categoryID
      category {
        id
        createdAt
        updatedAt
        name
        description
        meals {
          id
          createdAt
          updatedAt
          categoryID
          category {
            id
            createdAt
            updatedAt
            name
            description
            owner
            __typename
          }
          name
          description
          owner
          __typename
        }
        owner
        __typename
      }
      number
      __typename
    }
    paused
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationMutationVariables,
  APITypes.UpdateNotificationMutation
>;
export const deleteCategory = /* GraphQL */ `mutation DeleteCategory($id: ID!) {
  deleteCategory(id: $id) {
    id
    createdAt
    updatedAt
    name
    description
    meals {
      id
      createdAt
      updatedAt
      categoryID
      category {
        id
        createdAt
        updatedAt
        name
        description
        meals {
          id
          createdAt
          updatedAt
          categoryID
          category {
            id
            createdAt
            updatedAt
            name
            description
            owner
            __typename
          }
          name
          description
          owner
          __typename
        }
        owner
        __typename
      }
      name
      description
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCategoryMutationVariables,
  APITypes.DeleteCategoryMutation
>;
export const deleteMeal = /* GraphQL */ `mutation DeleteMeal($id: ID!) {
  deleteMeal(id: $id) {
    id
    createdAt
    updatedAt
    categoryID
    category {
      id
      createdAt
      updatedAt
      name
      description
      meals {
        id
        createdAt
        updatedAt
        categoryID
        category {
          id
          createdAt
          updatedAt
          name
          description
          meals {
            id
            createdAt
            updatedAt
            categoryID
            name
            description
            owner
            __typename
          }
          owner
          __typename
        }
        name
        description
        owner
        __typename
      }
      owner
      __typename
    }
    name
    description
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMealMutationVariables,
  APITypes.DeleteMealMutation
>;
export const deleteNotification = /* GraphQL */ `mutation DeleteNotification($id: ID!) {
  deleteNotification(id: $id) {
    id
    createdAt
    updatedAt
    name
    sendMonday
    sendTuesday
    sendWednesday
    sendThursday
    sendFriday
    sendSaturday
    sendSunday
    categories {
      categoryID
      category {
        id
        createdAt
        updatedAt
        name
        description
        meals {
          id
          createdAt
          updatedAt
          categoryID
          category {
            id
            createdAt
            updatedAt
            name
            description
            owner
            __typename
          }
          name
          description
          owner
          __typename
        }
        owner
        __typename
      }
      number
      __typename
    }
    paused
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationMutationVariables,
  APITypes.DeleteNotificationMutation
>;
