import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";
import SelectInput from "../../components/input/select/SelectInput";
import { useErrorBoundary } from "react-error-boundary";
import Loading from "../../components/loading/Loading";
import {
  getInitialNotification,
  loadNotification,
} from "../../helpers/NotificationCommon";
import TextInput from "../../components/input/text/TextInput";
import { useLocation } from "react-router-dom";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";
import FormTitle from "../../components/form-title/FormTitle";

function ViewNotificationPage({ intl }) {
  const { notificationId } = useParams();
  const [notification, setNotification] = useState(getInitialNotification());
  const [loading, setLoading] = useState(true);
  const { showBoundary } = useErrorBoundary();
  const location = useLocation();
  const comingFromAddPage = location.state?.comingFromAddPage;

  useEffect(() => {
    loadNotification(
      notificationId,
      setNotification,
      setLoading,
      showBoundary,
      intl
    );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationId]);

  function renderCategoryInputs() {
    return notification.categories.map((categoryInput, index) => {
      return (
        <fieldset
          key={`category-${index}-number`}
          className="py-6 border-y border-gray-300 sm:py-0 sm:border-y-0"
        >
          <TextInput
            labelTextKey="Category"
            id={`category-${index}-name`}
            value={categoryInput.category.name}
            disabled={true}
          />
          <TextInput
            labelTextKey="category.number.meals"
            id={`category-${index}-meals`}
            value={categoryInput.number}
            disabled={true}
          />
        </fieldset>
      );
    });
  }

  const renderForm = () => {
    return (
      <form>
        <div className="space-y-10 sm:space-y-12">
          <FormTitle titleKey="notification.view" />

          <div className="mt-10 space-y-8 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:pb-0">
            <TextInput
              labelTextKey="notification.name"
              id="notification-name"
              value={notification.name}
              disabled={true}
            />

            <fieldset>
              <legend className="sr-only">Send notification on days</legend>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6">
                <div
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  aria-hidden="true"
                >
                  <FormattedMessage id="notification.send.on" />
                </div>
                <div className="mt-4 sm:col-span-2 sm:mt-0">
                  <div className="max-w-lg space-y-6">
                    <SelectInput
                      id="sendMonday"
                      value={notification.sendMonday}
                      isDisabled={true}
                    />
                    <SelectInput
                      id="sendTuesday"
                      value={notification.sendTuesday}
                      isDisabled={true}
                    />
                    <SelectInput
                      id="sendWednesday"
                      value={notification.sendWednesday}
                      isDisabled={true}
                    />
                    <SelectInput
                      id="sendThursday"
                      value={notification.sendThursday}
                      isDisabled={true}
                    />
                    <SelectInput
                      id="sendFriday"
                      value={notification.sendFriday}
                      isDisabled={true}
                    />
                    <SelectInput
                      id="sendSaturday"
                      value={notification.sendSaturday}
                      isDisabled={true}
                    />
                    <SelectInput
                      id="sendSunday"
                      value={notification.sendSunday}
                      isDisabled={true}
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            {renderCategoryInputs()}
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <Link to={"/notifications/edit/" + notification.id}>
            <button
              type="button"
              className="rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <FormattedMessage id="Edit" />
            </button>
          </Link>
          {comingFromAddPage && (
            <Link to={"/notifications/add"}>
              <button
                type="button"
                className="block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <FormattedMessage id="add.new" />
              </button>
            </Link>
          )}
        </div>
      </form>
    );
  };

  return (
    <HalfWidthPageWrapper>
      {loading && <Loading />}
      {!loading && renderForm()}
    </HalfWidthPageWrapper>
  );
}

export default injectIntl(ViewNotificationPage);
