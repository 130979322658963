import List from "../../components/list/List";
import { injectIntl } from "react-intl";
import { listMeals } from "../../graphql/queries";
import { deleteMeal } from "../../graphql/mutations";
import { executeGraphQL } from "../../helpers/APIHelper";
import { useErrorBoundary } from "react-error-boundary";
import { toast } from "react-toastify";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";

function ListMealsPage({ intl }) {
  const { showBoundary } = useErrorBoundary();

  const handleFetchItems = async (nextToken) => {
    const apiData = await executeGraphQL(
      listMeals,
      {
        nextToken: nextToken,
      },
      null,
      showBoundary
    );

    if (apiData && apiData.listMeals) {
      return {
        items: apiData.listMeals.items,
        token: apiData.listMeals.nextToken,
      };
    } else {
      toast.error(intl.formatMessage({ id: "apihelper.loadMeals.error" }), {
        position: "bottom-right",
      });
    }
  };

  const removeMealWithId = async (mealId) => {
    if (mealId) {
      await executeGraphQL(
        deleteMeal,
        {
          id: mealId,
        },
        null,
        showBoundary
      );
    }
  };

  return (
    <HalfWidthPageWrapper>
      <List
        title={intl.formatMessage({ id: "Meals" })}
        removeFunction={removeMealWithId}
        emptyListTitle={intl.formatMessage({
          id: "empty.list.meals.title",
        })}
        emptyListDescription={intl.formatMessage({
          id: "empty.list.meals.description",
        })}
        emptyListNewText={intl.formatMessage({
          id: "empty.list.meals.new",
        })}
        fetchItemsGraphQLQuery={handleFetchItems}
      />
    </HalfWidthPageWrapper>
  );
}

export default injectIntl(ListMealsPage);
