import List from "../../components/list/List";
import { injectIntl } from "react-intl";
import { listNotifications } from "../../graphql/queries";
import { deleteNotification } from "../../graphql/mutations";
import { executeGraphQL } from "../../helpers/APIHelper";
import { useErrorBoundary } from "react-error-boundary";
import { toast } from "react-toastify";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";

function ListNotificationsPage({ intl }) {
  const { showBoundary } = useErrorBoundary();

  const handleFetchItems = async (nextToken) => {
    const apiData = await executeGraphQL(
      listNotifications,
      {
        nextToken: nextToken,
      },
      null,
      showBoundary
    );

    if (apiData && apiData.listNotifications) {
      return {
        items: apiData.listNotifications.items,
        token: apiData.listNotifications.nextToken,
      };
    } else {
      toast.error(
        intl.formatMessage({ id: "apihelper.loadNotifications.error" }),
        { position: "bottom-right" }
      );
    }
  };

  const removeNotificationWithId = async (notificationId) => {
    if (notificationId) {
      await executeGraphQL(
        deleteNotification,
        {
          id: notificationId,
        },
        null,
        showBoundary
      );
    }
  };

  return (
    <HalfWidthPageWrapper>
      <List
        title={intl.formatMessage({ id: "Notifications" })}
        removeFunction={removeNotificationWithId}
        emptyListTitle={intl.formatMessage({
          id: "empty.list.notifications.title",
        })}
        emptyListDescription={intl.formatMessage({
          id: "empty.list.notifications.description",
        })}
        emptyListNewText={intl.formatMessage({
          id: "empty.list.notifications.new",
        })}
        fetchItemsGraphQLQuery={handleFetchItems}
      />
    </HalfWidthPageWrapper>
  );
}

export default injectIntl(ListNotificationsPage);
