import { generateClient } from "aws-amplify/api";
import { toast } from "react-toastify";

const client = generateClient();

export async function executeGraphQL(
  query,
  variables,
  successMessage,
  errorBoundary
) {
  try {
    const response = await client.graphql({
      query: query,
      variables: variables,
    });

    if (response.errors) {
      errorBoundary(response.errors);
    } else {
      toast.success(successMessage, { position: "bottom-right" });
      return response.data;
    }
  } catch (error) {
    errorBoundary(error);
  }
}
