export function validateCategory(category, intl) {
  let isValid = true;
  let validationErrors = {};

  if (!category.name || category.name.length === 0) {
    isValid = false;
    validationErrors["name"] = intl.formatMessage({
      id: "category.name.validation.empty",
    });
  }

  return { isValid: isValid, validationErrors: validationErrors };
}
