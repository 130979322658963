import { listCategories, getCategory } from "../graphql/queries";
import { toast } from "react-toastify";
import { executeGraphQL } from "./APIHelper";

export async function loadAllCategories(setCategories, showBoundary, intl) {
  let categories = await fetchAllCategories(showBoundary, intl);
  setCategories(categories);
}

export async function loadCategory(
  categoryId,
  setCategory,
  setLoading,
  showBoundary,
  intl
) {
  const apiData = await executeGraphQL(
    getCategory,
    { id: categoryId },
    null,
    showBoundary
  );

  if (apiData && apiData.getCategory) {
    setCategory({ ...apiData.getCategory });
    setLoading(false);
  } else {
    toast.error(intl.formatMessage({ id: "apihelper.loadCategory.error" }), {
      position: "bottom-right",
    });
  }
}

export async function getCategoryCount(showBoundary, intl) {
  let categories = await fetchAllCategories(showBoundary, intl);
  return categories.length;
}

async function fetchAllCategories(showBoundary, intl) {
  let apiData = await executeGraphQL(listCategories, null, null, showBoundary);

  if (apiData && apiData.listCategories) {
    let categories = apiData.listCategories.items;

    while (apiData.listCategories.nextToken) {
      apiData = await executeGraphQL(
        listCategories,
        { nextToken: apiData.listCategories.nextToken },
        null,
        showBoundary
      );

      if (apiData && apiData.listCategories) {
        categories.push(...apiData.listCategories.items);
      } else {
        toast.error(
          intl.formatMessage({ id: "apihelper.loadCategories.error" }),
          {
            position: "bottom-right",
          }
        );
        return [];
      }
    }

    return categories;
  } else {
    toast.error(intl.formatMessage({ id: "apihelper.loadCategories.error" }), {
      position: "bottom-right",
    });
    return [];
  }
}

export function getInitialCategory() {
  return {
    name: "",
    id: "",
    description: "",
  };
}
