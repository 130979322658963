import List from "../../components/list/List";
import { injectIntl } from "react-intl";
import { listCategories, mealsByCategoryID } from "../../graphql/queries";
import {
  deleteCategory,
  deleteMeal,
  deleteNotification,
} from "../../graphql/mutations";
import { executeGraphQL } from "../../helpers/APIHelper";
import { useErrorBoundary } from "react-error-boundary";
import { toast } from "react-toastify";
import { getNotificationsUsingCategory } from "../../helpers/NotificationCommon";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";

function ListCategoriesPage({ intl }) {
  const { showBoundary } = useErrorBoundary();

  const handleFetchItems = async (nextToken) => {
    const apiData = await executeGraphQL(
      listCategories,
      {
        nextToken: nextToken,
      },
      null,
      showBoundary
    );

    if (apiData && apiData.listCategories) {
      return {
        items: apiData.listCategories.items,
        token: apiData.listCategories.nextToken,
      };
    } else {
      toast.error(
        intl.formatMessage({ id: "apihelper.loadCategories.error" }),
        {
          position: "bottom-right",
        }
      );
    }
  };

  const loadMealsOfCategory = async (categoryId) => {
    const apiData = await executeGraphQL(
      mealsByCategoryID,
      {
        categoryID: categoryId,
      },
      null,
      showBoundary
    );

    if (apiData && apiData.mealsByCategoryID) {
      return apiData.mealsByCategoryID.items;
    } else {
      toast.error(intl.formatMessage({ id: "apihelper.loadMeals.error" }), {
        position: "bottom-right",
      });
    }
  };

  const removeMealsBelongingToCategory = async (categoryId) => {
    const categoryMeals = await loadMealsOfCategory(categoryId);

    if (!categoryMeals) {
      return;
    }

    for (const meal of categoryMeals) {
      await executeGraphQL(
        deleteMeal,
        {
          id: meal.id,
        },
        null,
        showBoundary
      );
    }
  };

  const removeNotificationsUsingCategory = async (categoryId) => {
    const notifications = await getNotificationsUsingCategory(
      categoryId,
      showBoundary,
      intl
    );

    if (!notifications) {
      return;
    }

    for (const notification of notifications) {
      await executeGraphQL(
        deleteNotification,
        {
          id: notification.id,
        },
        null,
        showBoundary
      );
    }
  };

  const removeCategoryWithId = async (categoryId) => {
    if (categoryId) {
      await removeMealsBelongingToCategory(categoryId);
      await removeNotificationsUsingCategory(categoryId);

      await executeGraphQL(
        deleteCategory,
        {
          id: categoryId,
        },
        null,
        showBoundary
      );
    }
  };

  return (
    <HalfWidthPageWrapper>
      <List
        title={intl.formatMessage({ id: "Categories" })}
        name={intl.formatMessage({ id: "Category" })}
        removeFunction={removeCategoryWithId}
        emptyListTitle={intl.formatMessage({
          id: "empty.list.categories.title",
        })}
        emptyListDescription={intl.formatMessage({
          id: "empty.list.categories.description",
        })}
        emptyListNewText={intl.formatMessage({
          id: "empty.list.categories.new",
        })}
        fetchItemsGraphQLQuery={handleFetchItems}
      />
    </HalfWidthPageWrapper>
  );
}

export default injectIntl(ListCategoriesPage);
