import { getMeal } from "../graphql/queries";
import { toast } from "react-toastify";
import { executeGraphQL } from "./APIHelper";
import { listMeals } from "../graphql/queries";

export async function loadMeal(
  mealId,
  setMeal,
  setLoading,
  showBoundary,
  intl
) {
  const apiData = await executeGraphQL(
    getMeal,
    { id: mealId },
    null,
    showBoundary
  );

  if (apiData && apiData.getMeal) {
    setMeal({ ...apiData.getMeal });
    setLoading(false);
  } else {
    toast.error(intl.formatMessage({ id: "apihelper.loadMeal.error" }), {
      position: "bottom-right",
    });
  }
}

export async function getMealCount(showBoundary, intl) {
  let apiData = await executeGraphQL(listMeals, null, null, showBoundary);

  if (apiData && apiData.listMeals) {
    let mealCount = apiData.listMeals.items.length;

    while (apiData.listMeals.nextToken != null) {
      apiData = await executeGraphQL(
        listMeals,
        {
          nextToken: apiData.listMeals.nextToken,
        },
        null,
        showBoundary
      );

      if (apiData && apiData.listMeals) {
        mealCount += apiData.listMeals.items.length;
      } else {
        toast.error(intl.formatMessage({ id: "apihelper.loadMeals.error" }), {
          position: "bottom-right",
        });
        return NaN;
      }
    }

    return mealCount;
  } else {
    toast.error(intl.formatMessage({ id: "apihelper.loadMeals.error" }), {
      position: "bottom-right",
    });
    return NaN;
  }
}

export function getInitialMeal() {
  return {
    id: "",
    name: "",
    description: "",
    category: {
      id: "",
      name: "",
    },
  };
}
