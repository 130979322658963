Object.defineProperty(exports, "__esModule", { value: true });
exports.listNotifications =
  exports.getNotification =
  exports.listCategories =
  exports.getCategory =
  exports.mealsByCategoryID =
  exports.listMeals =
  exports.getMeal =
    void 0;
exports.getMeal = `query GetMeal($id: ID!) {
  getMeal(id: $id) {
    id
    categoryID
    category {
      id
      name
      description
    }
    name
    description
  }
}
`;
exports.listMeals = `query ListMeals($limit: Int, $nextToken: String) {
  listMeals(limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
    }
    nextToken
  }
}
`;
exports.mealsByCategoryID = `query MealsByCategoryID($categoryID: ID!, $limit: Int, $nextToken: String) {
  mealsByCategoryID(
    categoryID: $categoryID
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
    }
    nextToken
  }
}
`;
exports.getCategory = `query GetCategory($id: ID!) {
  getCategory(id: $id) {
    id
    name
    description
  }
}
`;
exports.listCategories = `query ListCategories($limit: Int, $nextToken: String) {
  listCategories(limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
    }
    nextToken
  }
}
`;
exports.getNotification = `query GetNotification($id: ID!) {
  getNotification(id: $id) {
    id
    createdAt
    updatedAt
    name
    sendMonday
    sendTuesday
    sendWednesday
    sendThursday
    sendFriday
    sendSaturday
    sendSunday
    categories {
      categoryID
      number
    }
    paused
  }
}
`;
exports.listNotifications = `query ListNotifications($limit: Int, $nextToken: String) {
  listNotifications(limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      name
      sendMonday
      sendTuesday
      sendWednesday
      sendThursday
      sendFriday
      sendSaturday
      sendSunday
      categories {
        categoryID
        number
      }
      paused
      owner
    }
    nextToken
    __typename
  }
}
`;
