import { FormattedMessage } from "react-intl";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";

function NotFoundPage() {
  return (
    <HalfWidthPageWrapper>
      <div className="text-center">
        <p className="text-base font-semibold text-indigo-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          <FormattedMessage id="page.not.found" />
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          <FormattedMessage id="page.not.found.text" />
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href="/"
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <FormattedMessage id="go.back.home" />
          </a>
          <a
            href="mailto:support@yumalarm.com"
            className="text-sm font-semibold text-gray-900"
          >
            <FormattedMessage id="contact.support" />
            <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </HalfWidthPageWrapper>
  );
}

export default NotFoundPage;
