import { FormattedMessage } from "react-intl";
import { useContext, useEffect, useState } from "react";
import Loading from "../../components/loading/Loading";
import {
  getReferralCode,
  getReferralCount,
} from "../../helpers/SubscriptionHelper";
import { CONSTANTS } from "../../constants/Constants";
import { UserContext } from "../../wrappers/user/UserDataWrapper";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";

function ReferralsPage() {
  const [numberOfReferrals, setNumberOfReferrals] = useState(0);
  const [referralCode, setReferralCode] = useState("");
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext);
  const percentageCompleted =
    (numberOfReferrals / CONSTANTS.MAX_REFERRALS) * 100;

  useEffect(() => {
    setReferralCode(getReferralCode(user));
    setNumberOfReferrals(getReferralCount(user));
    setLoading(false);
  }, [user]);

  const renderReferrals = () => {
    return (
      <div className="w-full flex flex-col items-center text-center">
        <div className="w-full mx-auto py-6 sm:py-8 lg:py-10">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
            <FormattedMessage id="referrals.code" />
            <br />
            {referralCode}
          </h2>
          <p className="mx-auto mt-6 text-lg leading-8 text-gray-600">
            <FormattedMessage id="referrals.description" />
          </p>
        </div>

        <div className="w-full flex flex-col items-center px-4 py-5 lg:mx-3 lg:w-1/3 lg:rounded-xl lg:shadow-lg lg:border-4 lg:border-solid lg:border-gray-300">
          <div className="text-2xl text-center font-medium py-2">
            <FormattedMessage id="referrals.count" />
          </div>
          <div className="h-2 w-full bg-neutral-600 lg:h-6 lg:text-center">
            <div
              className="h-2 lg:h-6 bg-green-500"
              style={{
                width: `${percentageCompleted}%`,
              }}
            ></div>
          </div>
          <div className="text-center mt-1 text-2xl font-semibold tracking-tight">
            {numberOfReferrals}/{CONSTANTS.MAX_REFERRALS}
          </div>
        </div>
      </div>
    );
  };

  return (
    <HalfWidthPageWrapper>
      {loading && <Loading />}
      {!loading && renderReferrals()}
    </HalfWidthPageWrapper>
  );
}

export default ReferralsPage;
