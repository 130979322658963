import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";
import { createMeal as createMealMutation } from "../../graphql/mutations";
import { executeGraphQL } from "../../helpers/APIHelper";
import { useNavigate } from "react-router-dom";
import CategoryAutocomplete from "../../components/autocomplete/CategoryAutocomplete";
import { validateMeal } from "../../validation/MealValidation";
import { useErrorBoundary } from "react-error-boundary";
import { toast } from "react-toastify";
import { loadAllCategories } from "../../helpers/CategoryCommon";
import { getInitialMeal } from "../../helpers/MealCommon";
import TextInput from "../../components/input/text/TextInput";
import { canAddMeals } from "../../helpers/PlanLimitHelper";
import LimitReached from "../../components/limitReached/LimitReached";
import TextAreaInput from "../../components/input/text/TextAreaInput";
import { UserContext } from "../../wrappers/user/UserDataWrapper";
import FormTitle from "../../components/form-title/FormTitle";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";

function AddMealPage({ intl }) {
  const [meal, setMeal] = useState(getInitialMeal());
  const [categories, setCategories] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [limitReached, setLimitReached] = useState(false);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const initalLoad = async () => {
      let limitNotReached = await canAddMeals(user, showBoundary, intl);
      setLimitReached(!limitNotReached);

      if (limitNotReached) {
        loadAllCategories(setCategories, showBoundary, intl);
      }
    };

    initalLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSaveClick = async () => {
    const validationResult = validateMeal(meal, intl);
    setValidationErrors(validationResult.validationErrors);

    if (!validationResult.isValid) {
      return;
    }

    const apiData = await executeGraphQL(
      createMealMutation,
      {
        input: {
          name: meal.name,
          categoryID: meal.category.id,
          description: meal.description,
        },
      },
      intl.formatMessage({ id: "apihelper.createMeal.success" }),
      showBoundary
    );

    if (apiData && apiData.createMeal) {
      setMeal({ ...meal, id: apiData.createMeal.id });
      navigate("/meals/view/" + apiData.createMeal.id, {
        state: {
          comingFromAddPage: true,
        },
      });
    } else {
      toast.error(intl.formatMessage({ id: "apihelper.createMeal.error" }), {
        position: "bottom-right",
      });
    }
  };

  if (limitReached) {
    return (
      <HalfWidthPageWrapper>
        <LimitReached
          infoMessageKey="plan.limit.reached.meals.info"
          linkMessageKey="plan.limit.reached.link"
        />
      </HalfWidthPageWrapper>
    );
  } else {
    return (
      <HalfWidthPageWrapper>
        <form>
          <div className="space-y-10 sm:space-y-12">
            <FormTitle titleKey="meal.add.new" />

            <div className="mt-10 space-y-8 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:pb-0">
              <TextInput
                labelTextKey="meal.name"
                id="meal-name"
                validationError={validationErrors["name"]}
                value={meal.name}
                handleOnChange={(event) => {
                  setValidationErrors({ ...validationErrors, name: null });
                  setMeal({ ...meal, name: event.target.value });
                }}
              />

              <CategoryAutocomplete
                categories={categories}
                onCategorySelected={(selectedCategory) => {
                  setValidationErrors({
                    ...validationErrors,
                    category: null,
                  });
                  setMeal({ ...meal, category: selectedCategory });
                }}
                validationError={validationErrors["category"]}
                selectedCategory={meal.category}
              />

              <TextAreaInput
                labelTextKey="Description"
                id="meal-description"
                value={meal.description}
                handleOnChange={(event) =>
                  setMeal({
                    ...meal,
                    description: event.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <Link
              to="/meals"
              className="text-sm font-semibold leading-6 text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <FormattedMessage id="Cancel" />
            </Link>

            <button
              type="button"
              className="rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleSaveClick}
            >
              <FormattedMessage id="Save" />
            </button>
          </div>
        </form>
      </HalfWidthPageWrapper>
    );
  }
}

export default injectIntl(AddMealPage);
